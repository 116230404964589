import React from 'react'
 import {  Pagination, Scrollbar, A11y,Autoplay,FreeMode } from 'swiper/modules';
 import { Swiper, SwiperSlide } from 'swiper/react';
 import 'swiper/css';
 import 'swiper/css/navigation';
 import 'swiper/css/pagination';
import '../index.css';
import {SkillsUsing,SkillsLearning,SkillsOthers} from '../skills-data';
import SingleSkill from './SingleSkill';
import { useTranslation } from 'react-i18next';



export const ImageSwiper = () =>{
  const { t } = useTranslation();
  return (
<>
<div className='w-full text-center flex justify-center items-center'>
 <span className=' w-fit font-bold phone-450:mt-16  phone-450:text-2xl text-xl py-2 px-4 text-app-black border-b '>{t("Using Now")}</span>
</div>

<Swiper className='mt-4'

modules={[ Pagination, Scrollbar, A11y,Autoplay,FreeMode]}
breakpoints={{
  480:{ slidesPerView: 1, spaceBetween:10},
  600:{ slidesPerView: 2, spaceBetween:10},
  768:{ slidesPerView: 2, spaceBetween:10},
  1024:{slidesPerView: 3, spaceBetween:10},
  1200:{slidesPerView: 4, spaceBetween:10},  
  1400:{slidesPerView: 4, spaceBetween:10},  
  1920:{slidesPerView: 5, spaceBetween:10},  
}}

    freeMode={true}
     grabCursor={true}
     autoplay={{
         delay: 1500,
         disableOnInteraction: false,
       }}
      navigation={{ clickable: true }}
      
  
    >
{SkillsUsing.map((skill) => (

<div key={skill.id}>
<SwiperSlide key={"using-"+skill.id} className=''>
<SingleSkill key={"using-singleskill-"+skill.id} data={skill}/> 
</SwiperSlide>
</div>

))}
    </Swiper>

<div className='w-full text-center flex justify-center items-center'>
<span className=' w-fit font-bold mt-6  phone-450:text-2xl text-xl py-2 px-4 text-app-black border-b'>{t("Learning")}</span>
</div>

<Swiper className='mt-4'

modules={[ Pagination, Scrollbar, A11y,Autoplay,FreeMode]}
breakpoints={{
  480:{ slidesPerView: 1, spaceBetween:10},
  600:{ slidesPerView: 2, spaceBetween:10},
  768:{ slidesPerView: 2, spaceBetween:10},
  1024:{slidesPerView: 3, spaceBetween:10},
  1200:{slidesPerView: 4, spaceBetween:10},  
  1400:{slidesPerView: 4, spaceBetween:10},  
  1920:{slidesPerView: 5, spaceBetween:10},  
}}

    freeMode={true}
     grabCursor={true}
       autoplay={{
         delay: 1500,
         disableOnInteraction: false,
       }}
      navigation={{ clickable: true }}
      
      
 
    >
{SkillsLearning.map((skill) => (

<div key={skill.id}>
<SwiperSlide key={"learning-"+skill.id} className=''>
<SingleSkill key={"learning-singleskill-"+skill.id} data={skill}/> 
</SwiperSlide>
</div>

))}
</Swiper>
    <div className='w-full text-center flex justify-center items-center'>
    <span className='w-fit font-bold mt-6  phone-450:text-2xl text-xl py-2 px-4 text-app-black border-b'>{t("Others")}</span>
    </div>
<Swiper className='mt-4'

modules={[ Pagination, Scrollbar, A11y,Autoplay,FreeMode]}
breakpoints={{
  480:{ slidesPerView: 1, spaceBetween:10},
  600:{ slidesPerView: 2, spaceBetween:10},
  768:{ slidesPerView: 2, spaceBetween:10},
  1024:{slidesPerView: 3, spaceBetween:10},
  1200:{slidesPerView: 4, spaceBetween:10},  
  1400:{slidesPerView: 4, spaceBetween:10},  
  1920:{slidesPerView: 5, spaceBetween:10},  
}}

    freeMode={true}
    
     grabCursor={true}
       autoplay={{
         delay: 1500,
         disableOnInteraction: false,
       }}
      navigation={{ clickable: true }}
      
      
    
    >
{SkillsOthers.map((skill) => (

<div key={skill.id}>
<SwiperSlide key={"others-"+skill.id} className=''>
<SingleSkill key={"others-singleskill-"+skill.id} data={skill}/> 
</SwiperSlide>
</div>

))}
    </Swiper>
    </>
  );
}

export default ImageSwiper;
