import React from 'react'
import banner from '../images/portfolio-banner.png';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { TbWorldShare } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
// import iklimbilinci from '../images/iklimbilinci.png';
import websiteportfolio from '../images/example-website-portfolio.png';
import menuapp from '../images/menu-app-portfolio.png';
import petworldportfolio from '../images/petworld-portfolio.png';
import eCommerce from '../images/e-commerce.png';
// import countries from '../images/countries.png';
import englishTests from '../images/english-tests.png';
import todoList from '../images/todo-list.png';
import PropTypes from 'prop-types';
import purchasingpower from '../images/react-purchasing-power.png';


const Projects =[
  {
    id:999,
  ProjectName:'English Tests',
  ProjectUrl:'english-tests-erenkoc.vercel.app',
  ProjectGithubUrl:'github.com/Eren-Koc/english-tests',
  ProjectImg:englishTests,
  },
  {
    id:1007,
  ProjectName:'MENU APP',
  ProjectUrl:'menu-app-erenkocs-projects.vercel.app',
  ProjectGithubUrl:'github.com/Eren-Koc/menu-app',
   ProjectImg:menuapp,
  },
  {
    id:1000,
  ProjectName:'To-Do List',
  ProjectUrl:'todo-list-erenkoc.vercel.app',
  ProjectGithubUrl:'github.com/Eren-Koc/todo-list',
  ProjectImg:todoList,
  },
  // {
  //   id:1001,
  // ProjectName:'IKLIM BILINCI',
  // ProjectUrl:'iklimbilinci.com.tr',
  // ProjectGithubUrl:'github.com/Eren-Koc/iklimbilinci.com.tr',
  // ProjectImg:iklimbilinci,
  // },
  {
    id:1002,
  ProjectName:'API E-COMMERCE',
  ProjectUrl:'react-e-commerce-erenkoc.vercel.app/',
  ProjectGithubUrl:'github.com/Eren-Koc/react-e-commerce',
  ProjectImg:eCommerce,
  },
  // {
  //   id:1003,
  // ProjectName:'COUNTRIES PORTFOLIO WEBSITE',
  // ProjectUrl:'react-countries-erenkoc.vercel.app',
  // ProjectGithubUrl:'github.com/Eren-Koc/react-countries',
  // ProjectImg:countries,
  // },
  {
    id:1004,
    ProjectName:'WEBSITE DESIGN',
    ProjectUrl:'react-app-website-frontend.vercel.app/',
    ProjectGithubUrl:'github.com/Eren-Koc/react-app-website-frontend',
    ProjectImg:websiteportfolio,
  },
  {
    id:1005,
  ProjectName:'PETWORLD PORTFOLIO WEBSITE',
  ProjectUrl:'react-example-petworld-website-erenkoc.vercel.app',
  ProjectGithubUrl:'github.com/Eren-Koc/react-example-petworld-website',
  ProjectImg:petworldportfolio,
  },
  {
    id:1006,
  ProjectName:'PURCHASING POWER',
  ProjectUrl:'react-purchasing-power-erenkoc.vercel.app/',
  ProjectGithubUrl:'github.com/Eren-Koc/react-purchasing-power',
  ProjectImg:purchasingpower,
  },
]

const ProjectsPortfolio = ({visibleServices,setVisibleServices}) => {
  const { t } = useTranslation();
  return (
    <div id='portfolio'>  
      <div id='portfolio-banner' className='w-full mt-16 flex justify-center flex-col relative items-center'>
        <div className='flex z-10 w-fit flex-row items-center phone-450:absolute phone-450:gap-16 gap-3'>
        <span className='border-b-2 py-2 px-4 font-bold text-2xl cursor-pointer '>{t("Portfolio")}</span>
        <span className='border-b border-app-dark/0 py-2 px-4 hover:border-app-dark/100 text-2xl cursor-pointer' onClick={()=> setVisibleServices(!visibleServices)} >{t("Services")}</span>
        </div>
      
       <img src={banner} alt="" className='relative z-0 phone-450:block hidden' />
    </div>
    

<ul className='w-full h-fit flex-wrap list-none flex justify-center items-center'>
      {Projects.map((project) => (
        <li className='list-none w-fit h-fit flex m-2 flex-wrap desktop:w-[calc(33.3%_-_1rem)] tablet-768:w-[calc(50%_-_1rem)] sm:w-[calc(100%_-_1rem)]' key={project.id}>
        <div className=' w-full relative flex flex-col justify-center items-center shadow-md py-4 rounded-md'>
        <img src={project.ProjectImg} className='w-full rounded-lg h-auto relative z-0' alt="" />
        <div className='w-full h-fit justify-center items-center flex flex-row'>
        <a rel="noreferrer" target='_blank' href={"https://"+project.ProjectUrl}>
          <button className='group phone-450:p-3 p-2 rounded-sm flex flex-row justify-center items-center phone-450:text-base text-sm bg-app-dark text-app-white phone-450:mx-2 mx-1 font-bold hover:bg-app-white shadow-sm hover:text-app-dark duration-300 ease-in'>
         {t("Visit Website")}
        <span><TbWorldShare className='text-app-white w-5 h-auto ml-2 group-hover:text-app-dark duration-300 ease-in'/></span>
        </button>
        </a>

        <a rel="noreferrer" target='_blank' href={"https://"+project.ProjectGithubUrl}><button className='group phone-450:p-3 p-2  rounded-sm phone-450:text-base text-sm flex flex-row justify-center items-center bg-app-dark text-app-white phone-450:mx-2 mx-1 font-bold shadow-sm  hover:bg-app-white hover:text-app-dark duration-300 ease-in'>{t("View Source Code")}
        <span><HiArrowNarrowRight className='text-app-white w-5 h-auto ml-2 group-hover:text-app-dark duration-300 ease-in'/></span>
        </button></a>
        </div>
        </div>
        </li>
      ))}
</ul>
    
    <div className='w-full h-16 bg-app-dark flex justify-center items-center text-app-white font-bold text-base'>{t("And Many More")}</div>
    </div>
  )
}
ProjectsPortfolio.propTypes = {
  visibleServices: PropTypes.bool,
  setVisibleServices:PropTypes.func
};

export default ProjectsPortfolio